import React, { ReactNode } from "react";
import { CSSObject, Paper } from "@mui/material";

interface SectionProps {
  children: ReactNode,
  id: string,
  elevation: number,
  height: number,
  sx?: CSSObject
}


export default function Section({children, id, elevation, height, sx}: SectionProps): React.JSX.Element {

  return(
    <Paper id={id} elevation={elevation} sx={{...sx, width: `100vw`, height: `${height}vh`, borderRadius: '0'}}>
      {children}
    </Paper>
  )

}
