import React from 'react';

export default function VideoItem ({sx, videoUrl, isMobile, typeString}) {

  return (
    <video 
      style={sx} 
      autoPlay 
      loop 
      muted
      playsInline
      preload='auto'
    >
      <source src={videoUrl} type={typeString} />
    </video>
  )
}